import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import BlogEntries from './statics/data/blogs.json'
import ProviderEntries from './statics/data/top-providers.json'

Vue.use(Router)

const blogRoutes = Object.keys(BlogEntries).map(section => {
  const children = BlogEntries[section].map(child => ({
    path: child.id,
    name: child.id,
    component: () => import(`./markdowns/learn/${child.id}.md`)
  }))
  return {
    path: `/${section}`,
    name: section,
    component: () => import('./views/Blog.vue'),
    children
  }
})

const topProviderRoutes = Object.keys(ProviderEntries).map(section => {
  const children = ProviderEntries[section].map(child => ({
    path: child.id,
    name: child.id,
    props:true,
    component: () => import(`./markdowns/providers/${child.id}.md`)
  }))
  return {
    path: `/${section}`,
    name: section,
    component: () => import('./views/Provider.vue'),
    props:true,
    children
  }
})

export default new Router({
  /* eslint-disable */
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
    },
    {
      path: '/learn',
      name: 'learn',
      component: () => import('./views/Learn.vue')
    },
    {
      path: '/faq',
      name: 'faq',
      component: () => import('./views/FAQ.vue')
    },
    {
      path: '/feedback',
      name: 'feedback',
      component: () => import('./views/Feedback.vue')
    },
    ...blogRoutes,
    ...topProviderRoutes,
    {
    path: '*',
      component: Home
    }
  ]
})
