<template>
  <div class="provider-card" @click="$router.push({name: provider.id, params: {provider}})">
    <img :src="require(`@/assets/${provider.logoURL}`)" :alt="provider.name">
    <p class="name mt-2 mb-0">{{ provider.name }}</p>
    <star-rating :increment="0.5" :star-size="16" :show-rating="false" :read-only="true" :rating="provider.rating"></star-rating>
    <p class="mt-2">{{ provider.preview }}</p>
    <a v-bind:href="provider.url" class="btn-more">Read More</a>
  </div>
</template>

<script>
import StarRating from 'vue-star-rating'

export default {
  components: {
    StarRating
  },
  props: {
    provider: {
      name: String,
      url: String,
      rating: Number,
      logoURL: String,
      preview: String
    }
  }
}
</script>

<style lang="scss">

.provider-card {
  box-shadow:0 10px 40px 0 rgba(25,104,252,0.2);
  padding: 15px;
  padding-bottom: 45px;
  text-align: left;
  max-width: 380px;
  margin: auto;
  margin-top: 3rem;
  transition-duration: 0.3s;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }

  .name {
    font-weight: 600;
  }

  .vue-star-rating {
    margin-top: -3px;
  }

  a {
    font-weight: 500;
    font-size: 0.85rem;
  }

  img {
    max-height: 45px;
    margin-right: auto;
    display: block;
  }

  .btn-more {
    position: absolute;
    bottom: 15px;
  }

  @media only screen and (min-width: 992px) {
    max-width: none;
    margin-top: 0;
    height: 100%;
  }
}

</style>
