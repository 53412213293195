import TopProviders from '@/components/TopProviders.vue'
import SectionHeader from '@/components/SectionHeader.vue'
import LineCard from '@/components/LineCard.vue'
import ProviderCard from '@/components/ProviderCard.vue'
import Reviews from '@/components/Reviews.vue'
import GetStarted from '@/components/GetStarted.vue'
import TOPPROVIDERS from '@/statics/data/top-providers.json'


export default {
  name: 'home',
  components: {
    TopProviders,
    SectionHeader,
    LineCard,
    ProviderCard,
    Reviews,
    GetStarted
  },
  mounted() {
    if (this.$route.hash == "get-started")
    {
      this.$scrollTo('.get-started-widget', 500, {offset: -150})
    }
    if (this.$route.hash == "process")
    {
      this.$scrollTo('.process', 500, {offset: -150})
    }

  },
  data: function () {
    return {
      process: [
        { title: 'Mystery Shop', subtitle: 'Confirm they are asking right questions' },
        { title: 'Pricing & Fees', subtitle: 'In line with industry norms' },
        { title: 'Better Business Rating', subtitle: 'No red flags, good ratings' },
        { title: 'Consumer Affairs Review', subtitle: 'Over 10k reviews' },
        { title: 'Compliance', subtitle: 'Doing it the right way' },
        { title: 'Proven Ability', subtitle: 'Years in business' }
      ]
    }
  },
  computed: {
    providers() {
      return TOPPROVIDERS.providers
    }
  },
}
