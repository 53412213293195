<template>
  <div>
    <b-navbar fixed="top" id="nav">
        <div class="d-nav-bg d-none d-lg-block"></div>
        <b-navbar-brand to="/" :class="{ 'd-none': mobileMenuIsActive }">
          <img src="../assets/logo.svg" alt="debt settlement logo">
        </b-navbar-brand>
        <router-link to="/about" class="nav-link ml-xl-5">About</router-link>
        <router-link to="/learn" class="nav-link">Learn</router-link>
        <router-link to="/faq" class="nav-link">FAQs</router-link>

        <button class="hamburger hamburger--spring ml-auto d-lg-none" type="button" role="button" aria-label="Menu" @click="toggleMobileMenu" :class="{ 'is-active': mobileMenuIsActive }" data-toggle="modal" data-target="#menu-modal">
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
        </button>
        <p class="ml-auto mr-3 mr-xl-5 d-none phone phone-nav">888-767-3328</p>
        <b-button variant="primary" class="d-none ml-auto d-lg-block gs-btn" v-on:click="scrollToGS">Get Started</b-button>
      </b-navbar>

      <div class="mobile-menu right d-lg-none" :class="{ 'is-active': mobileMenuIsActive }">
        <div class="d-block mx-auto">
          <router-link to="/" @click.native="toggleMobileMenu">
            <img src="../assets/logo.svg" alt="debt settlement logo">
          </router-link>
          <p class="call-us">Call us now</p>
          <a class="phone" href="tel:8887673328">888-767-3328</a>
        </div>
        <div class="d-block mx-auto w-100 px-5">
            <router-link to="/about" @click.native="toggleMobileMenu">About</router-link>
            <router-link to="/learn" @click.native="toggleMobileMenu">Learn</router-link>
            <router-link to="/faq" @click.native="toggleMobileMenu">FAQs</router-link>
            <b-button variant="primary" @click="toggleMobileMenu();scrollToGS();">Get Started</b-button>
        </div>

      </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'Nav',
  data: function () {
    return {
      mobileMenuIsActive: false
    }
  },
  methods: {
    toggleMobileMenu: function () {
      this.mobileMenuIsActive = !this.mobileMenuIsActive
    },
    scrollToGS: function () {
      if (this.$route.name != 'home') {
        this.$router.push({ name: 'home', hash: 'get-started'});
      }  else {
        this.$scrollTo('.get-started-widget', 500, {offset: -150})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../node_modules/hamburgers/_sass/hamburgers/hamburgers.scss';

a {
  color: #2c3e50;
  font-weight: 600;

  &.router-link-exact-active {
    color: var(--primary);
  }
}

.phone {
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 0;
}

.navbar {
  height: 60px;
  background-color: rgba($color: #FFFFFF, $alpha: 0.95);
  border-bottom: 1px solid #f0f0f0;
  overflow: hidden;

  @media only screen and (min-width: 1065px) {
    .phone-nav {
      display: block !important;
    }

    .gs-btn {
      margin-left: 0 !important;
    }
  }

  .hamburger {
    &:focus {
      outline: none;
    }
    .hamburger-box {
      margin-top: 7px;
    }
  }

  .navbar-brand {
    padding: 0;

    img {
      height: 30px;
    }
  }

  .nav-link {
    display: none;
  }


  @media only screen and (min-width: 992px) {

    height: 100px;
    padding: 30px 40px !important;

    .nav-link {
      display: block;
    }

    .navbar-brand img {
      height: 38px;
    }
  }

  @media only screen and (min-width: 992px) {
    padding: 30px 100px !important;
  }
}

.mobile-menu {

  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  background-color: #FFF;
  text-align: center;
  z-index: 1029;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  right: -100px;
  opacity: 0;
  -webkit-transition: opacity 0.15s ease-in, right 0.15s linear;
  -moz-transition: opacity 0.15s ease-in, right 0.15s linear;
  -o-transition: opacity 0.15s ease-in, right 0.15s linear;
  transition: opacity 0.15s ease-in, right 0.15s linear;
  padding-top: 80px;

  a {
    display: block;
    margin: auto;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 2rem;
  }

  .btn-primary {
    margin-bottom: 2rem;
    width: 100%;
  }

  &.is-active {
    right: 0;
    opacity: 1;
    pointer-events: initial;
  }

  img {
    height: 35px;
  }

  .call-us {
    font-weight: 500;
    font-size: 1.5rem;
    margin-bottom: 0;
    color: var(--primary);
  }

  .phone {
    font-weight: 600;
    font-size: 2rem;
  }

}

</style>
