<template>
  <div class="reviews">
    <b-container>
      <div class="swiper-button-prev"></div>
      <div class="swiper-container">
          <div class="swiper-wrapper">
              <div class="swiper-slide" v-bind:key="review.id" v-for="review in reviews">
                <Review v-bind:review="review"/>
              </div>
          </div>
          <div class="swiper-pagination"></div>
      </div>
      <div class="swiper-button-next"></div>
    </b-container>

  </div>
</template>

<script>
import Swiper from 'swiper'
import Review from '@/components/Review.vue'

export default {
  components: {
    Review
  },
  mounted: function () {
    // eslint-disable-next-line
     var mySwiper = new Swiper('.swiper-container', {
      spaceBetween: 30,
      slidesPerView: 'auto',
      centeredSlides: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        992: {
          centeredSlides: false,
          slidesPerView: 3
        }
      }
    })
  },
  data: function () {
    return {
      reviews: [
        { rating: 5, description: 'Vestibulum rutrum quam vitae fringilla tincidunt. Suspendisse nec tortor urna. ies metus', imgURL: 'https://i.pravatar.cc/300', from: 'Mark T. - Orange County, California' },
        { rating: 5, description: 'Vestibulum rutrum quam vitae fringilla tincidunt. Suspendisse nec tortor urna. ies metus', imgURL: 'https://i.pravatar.cc/300', from: 'Mark T. - Orange County, California' },
        { rating: 5, description: 'Vestibulum rutrum quam vitae fringilla tincidunt. Suspendisse nec tortor urna. ies metus', imgURL: 'https://i.pravatar.cc/300', from: 'Mark T. - Orange County, California' },
        { rating: 5, description: 'Vestibulum rutrum quam vitae fringilla tincidunt. Suspendisse nec tortor urna. ies metus', imgURL: 'https://i.pravatar.cc/300', from: 'Mark T. - Orange County, California' }
      ]
    }
  }
}
</script>

<style lang="scss">
@import '../../node_modules/swiper/css/swiper.min.css';

.swiper-container {
    width: 100%;
    height: auto;
}

.swiper-slide {
  width: 85%;
  max-width: 325px;

  @media only screen and (min-width: 992px) {
    max-width: none;
    width: initial;
  }
}
.swiper-pagination {
  position: relative;
  margin-top: 1rem;

  @media only screen and (min-width: 992px) {
    display: none;
  }
}

.container {
  position: relative;
}

[class*="swiper-button"] {
  display: none;

  &::after {
    font-size: 2rem;
  }

  @media only screen and (min-width: 992px) {
    display: block;
  }
}

.swiper-button-prev {
  left: -30px;
}

.swiper-button-next {
  right: -30px;
}
</style>
