<template>
  <div class="footer">
    <b-container>
      <router-link to="/">
      <img  src="../assets/logo.svg" alt="logo">

      </router-link>
      <p class="pt-4 mb-0">© {{ year }} DebtSettlement.org. All rights reserved.</p>
    </b-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      year: new Date().getFullYear()
    }
  }
}
</script>

<style lang="scss">
.footer {
  padding: 20px 10px;
  background-color: #f9f9f9;
  img {
    width: 200px;
  }

  p {
    font-size: 0.8rem;
  }

  @media only screen and (min-width: 992px) {
    img {
      width: 250px;
    }
    padding: 40px 0px;
  }
}
</style>
