<template>
  <div class="linecard pl-lg-4 pr-lg-5">
      <h4 v-html="title"></h4>
      <p >{{ description }}</p>
      <button class="btn btn-link" v-b-modal="'myModal' + modalId">Learn More</button>
      <b-modal :id="'myModal' + modalId" size="lg"  hide-footer>
         <h4 class="font-weight-bold" v-html="title"></h4>
         <p class="mt-3" v-html="detail"></p>
         <b-button class="mt-5 mx-auto d-block" v-scroll-to="'.get-started-widget'" @click="$bvModal.hide('myModal' + modalId)" variant="primary">Learn More</b-button>
      </b-modal>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'LineCard',
  props: {
    vs: String,
    description: String,
    url: String,
    detail: String,
    modalId: String
  },
  computed: {
    title: function () {
      return "Debt Settlement<br> vs <span>" + this.vs + "</span>"
    }
  }
}
</script>

<style lang="scss">
    .linecard {
      border-left: 4px solid var(--primary);
      padding: 10px 0px 10px 20px;
      text-align: left;


      h4 {
        font-weight: 600;
      }

      span {
        color: #1968FC;
      }

      .btn {
        font-weight: 600;
        background-color: transparent !important;
        border: none;
        text-align: left;
      }
    }
</style>
