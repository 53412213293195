<template>
<div class="section-header container">
  <h1 class="section-title">{{ title }}</h1>
  <hr>
  <h2 class="section-subtitle">{{ subtitle }}</h2>
  <a v-bind:href="url" class="extra btn-link pb-0">{{ urlTxt }}</a>
</div>
</template>

<script>
export default {
  props: {
    title: String,
    subtitle: String,
    url: String,
    urlTxt: String
  }
}
</script>

<style lang="scss">

.section-header {
  margin-top: 50px;
  margin-bottom: 30px;

  @media only screen and (min-width: 992px) {
    margin-top: 12vh;
    margin-bottom: 40px;
  }
}

.section-title {
    font-size: 1.75rem;
    font-weight: 700;
    line-height: 1.9rem;

   @media only screen and (min-width: 992px) {
      font-size: 2.75rem;
      line-height: 4rem;
      margin-bottom: 0;
    }
}

.section-subtitle {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
    max-width: 19rem;
    display: block;
    margin: auto;

    @media only screen and (min-width: 992px) {
      font-size: 1.25rem;
      line-height: 1.75rem;
      max-width: 30rem;
      margin-top: 10px;
      margin-left: 0;
    }
}

@media only screen and (min-width: 992px) {
  &.wider-subtitle {
    .section-subtitle {
      max-width: 38rem !important;
    }
  }

    &.nowrap-subtitle {
    .section-subtitle {
      max-width: none !important;
    }
  }
}

hr {
  height: 4px !important;
  background-color: var(--primary);
  margin: 0.75rem 0;

  @media only screen and (min-width: 992px) {
    max-width: 200px;
    margin-left: -3rem;
    height: 5px !important;
  }
}

.extra {
  font-weight: 700;
  font-size: 0.85rem;

  @media only screen and (min-width: 992px) {
    font-size: 1rem;
  }
}

</style>
