<template>
  <div class="top-providers">
    <div class="wrap">
      <h1>Top Debt<br> Settlement Providers</h1>
      <h2>Our team has put together a list of the top debt settlement providers for {{ year }}.</h2>
      <ol class="top-links">
        <li v-bind:key="provider.id" v-for="provider in providers" @click="$router.push({name: provider.id, params: {provider}})">
            <a class="top-link">
                <span class="">
                    <span class="pl-2">{{ provider.name }}</span>
                </span>
            </a>
            <star-rating :increment="0.5" :star-size="16" :read-only="true" :rating="provider.rating"></star-rating>
        </li>
      </ol>
    </div>
    <button  @click="scrollToProcess();" class="btn btn-link text-center mt-lg-3">Learn Our Rating System</button>
  </div>
</template>

<script>
import StarRating from 'vue-star-rating'
import TOPPROVIDERS from '@/statics/data/top-providers.json'

export default {
  components: {
    StarRating
  },
  data() {
    return {
      year: new Date().getFullYear()
    }
  },
  methods: {
    scrollToProcess: function () {
      if (this.$route.name != 'home')
      {
        this.$router.push({ name: 'home', hash: 'process'});
      }
      else
      {
       this.$scrollTo('.process', 500, {offset: -150})
      }
    }
  },
  computed: {
    providers() {
      return TOPPROVIDERS.providers
    }
  },
}
</script>

<style lang="scss">

.top-providers {
  background-color: #FFF;
  box-shadow: 0 4px 10px 0 rgba(0,0,0,0.5);
  width: 90%;
  margin: auto;
  max-width: 390px;
  text-align: left;
  padding-bottom: 20px;

  h1 {
    font-size: 1.4rem;
    line-height: 2rem;
    text-align: center;
  }

  h2 {
    font-size: 0.875rem;
    line-height: 1.15rem;
    text-align: center;
  }

  .btn-link {
    background-color: #f0f0f0;
    display: block;
    padding: 20px 0;
    width: 90%;
    margin: auto;
  }

  .wrap {
    padding: 18px;
  }


@media only screen and (min-width: 992px) {

  h1 {
    font-size: 1.6rem;
    text-align: left;
  }
  h2 {
    font-size: 1rem;
    text-align: left;
  }

    .wrap {
    padding: 20px 20px 0 20px;
  }

  .btn-link {
    width: calc(100% - 40px);

  }

}

.top-links {
    list-style: none;
    padding-left: 0;

    li {
      cursor: pointer;
      &:hover {
        background-color: whitesmoke;
        color: var(--primary);
      }
    }

    .top-link {
      border-radius: 5px;
      font-size: 1.25rem;
      line-height: 2.75rem;

      .btn-link {
        display: inline-block !important;
        text-align: left;
        margin: 0;
      }
    }

    .vue-star-rating {
      padding-left: 0.25rem;
      margin-top: -6px;
      color: var(--dark)
    }
  }
}
</style>
