<template>
  <div class="get-started-widget">
    <img src="../assets/free-tag.svg" v-if="hasBadge" alt="free consulation" class="gs-tag">
    <form id="mktoForm_1001" ref="mktoForm" class="d-none"></form>
    <div class="px-3 py-4 p-lg-5 gs-form mx-auto">
      <h1 v-html="title"></h1>
      <p class="subtitle mb-4" v-html="subtitle"></p>
      <b-form @submit="onSubmit" ref="leadForm">
        <div class="page-0" v-if="page === 0">
          <b-form-group label="Amount of Debt" label-for="input-amt-debt">
            <b-form-select v-model="form.debtamount" required id="input-amt-debt">
              <option :value="null" disabled>Select Amount</option>
              <option value="$0 - $4,999">$0 - $4,999</option>
              <option value="$5,000 - $7,499">$5,000 - $7,499</option>
              <option value="$7,500 - $9,999">$7,500 - $9,999</option>
              <option value="$10,000 - $14,999">$10,000 - $14,999</option>
              <option value="$15,000 - $19,999">$15,000 - $19,999</option>
              <option value="$20,000 - $29,999">$20,000 - $29,999</option>
              <option value="$30,000 - $39,999">$30,000 - $39,999</option>
              <option value="$40,000 - $49,999">$40,000 - $49,999</option>
              <option value="$50,000 - $59,999">$50,000 - $59,999</option>
              <option value="$60,000 - $69,999">$60,000 - $69,999</option>
              <option value="$70,000 - $79,999">$70,000 - $79,999</option>
              <option value="$80,000 - $89,999">$80,000 - $89,999</option>
              <option value="$90,000 - $99,000">$90,000 - $99,000</option>
              <option value="$100,000 +">$100,000 + </option>
            </b-form-select>
          </b-form-group>
          <b-form-group label="Employment Status" label-for="input-emp-status">
            <b-form-select v-model="form.employmentStatus" id="input-emp-status" required>
              <option :value="null" disabled>Select Status</option>
              <option value="Yes">Employed</option>
              <option value="No">Unemployed</option>
              <!-- <option value="self-employed">Self-Employed</option>
              <option value="retired">Retired</option> -->
            </b-form-select>
          </b-form-group>
          <b-form-group label="Approx. Credit Score" label-for="input-credit-score">
            <b-form-select v-model="form.creditScore" id="input-credit-score" required>
              <option :value="null" disabled>Select Range</option>
              <option value="Don't Know">Don't Know</option>
              <option value="300-549 (Bad)">300-549 (Bad)</option>
              <option value="550-619 (Poor)">550-619 (Poor)</option>
              <option value="620-679 (Fair)">620-679 (Fair)</option>
              <option value="680-739 (Good)">680-739 (Good)</option>
              <option value="680-739 (Excellent)">680-739 (Excellent)</option>
            </b-form-select>
          </b-form-group>
        </div>
        <div class="page-1" v-if="page === 1">
          <b-form-group label="First Name" label-for="input-firstName">
            <b-form-input id="input-firstName" v-model="form.FirstName" type="text" required placeholder="Enter name">
            </b-form-input>
          </b-form-group>
          <b-form-group label="Last Name" label-for="input-lastName">
            <b-form-input id="input-lastName" v-model="form.LastName" type="text" required placeholder="Enter name">
            </b-form-input>
          </b-form-group>
          <b-form-group label="Email Address" label-for="input-email">
            <b-form-input id="input-email" v-model="form.Email" type="email" required placeholder="Enter Email">
            </b-form-input>
          </b-form-group>
          <b-form-group label="Phone Number" label-for="input-phone">
            <b-form-input id="input-phone" v-model="form.Phone" v-mask="'(###) ###-####'" type="text" required
              placeholder="Enter phone number"></b-form-input>
          </b-form-group>
          <!-- <b-form-group label="Your Objectives" label-for="input-objectives">
            <b-form-checkbox-group id="input-objectives" v-model="form.objectives" name="flavour-2" stacked>
              <b-form-checkbox value="rid-of-debt">Get rid of debt</b-form-checkbox>
              <b-form-checkbox value="rebuild-credit">Rebuild credit</b-form-checkbox>
              <b-form-checkbox value="find-best-option">Find out best option for me</b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group> -->
        </div>
        <b-button type="submit" variant="primary" class="mt-4 mx-auto d-block" v-if="!isLoading && !completed">Continue
        </b-button>
        <p v-if="isLoading" class="mb-0 text-center">{{ loadingMessage }}</p>
        <Loader v-if="isLoading" />
      </b-form>
    </div>
  </div>

</template>

<script>
  import Loader from '@/components/Loader.vue'
  import axios from 'axios'

  export default {
    components: {
      Loader
    },
    data() {
      return {
        marketoForm: null,
        isLoading: false,
        title: "Get Started",
        subtitle: "We first need just a little information from you so we can get you the solution that best fits your needs.",
        loadingMessage: "Seeing if you qualify...",
        completed: false,
        badgeImg: require('../assets/free-tag.svg'),
        form: {
          FirstName: '',
          LastName: '',
          Phone: null,
          sourceurl: "https://debtsettlement.org" + this.$router.currentRoute.fullPath,
          debtamount: null,
          Email: '',
          address: null,
          customUUID: null,
          anonymousIP: null,
          city: null,
          state: null,
          postalCode: null,
          utmcampaign: Object.is(this.$route.query.utm_campaign, undefined) ? null : this.$route.query.utm_campaign,
          utmcontent: Object.is(this.$route.query.utm_content, undefined) ? null : this.$route.query.utm_content,
          utmkeyword: Object.is(this.$route.query.utm_keyword, undefined) ? null : this.$route.query.utm_keyword,
          utmmedium: Object.is(this.$route.query.utm_medium, undefined) ? null : this.$route.query.utm_medium,
          utmsource: Object.is(this.$route.query.utm_source, undefined) ? null : this.$route.query.utm_source,
          facebookId: null,
          gclid: null,
          creditScore: null,
          employmentStatus: null,
          preferredProvider: ''
        },
        page: 0,
        options: {
          reverse: true
        }
      }
    },
    props: {
      providerName: String,
      providerImgName: String,
      hasBadge: {
        default: true,
        type: Boolean
      }
    },
    mounted: function () {
      // eslint-disable-next-line
      MktoForms2.loadForm("https://app-ab39.marketo.com", "612-QNZ-913", 1001, function(form) {
        this.marketoForm = form;
         // eslint-disable-next-line
        form.onSuccess(function(values, followUpUrl) {
              this.isLoading = false
              this.page = 2
              this.title = "<span>Thank You</span>"
              this.subtitle =
                "We will connect you with one of our representatively shortly. Lets get you on the road to becoming financially healthy."
              this.completed = true
            return false;
        }.bind(this))
      }.bind(this));

      if (this.providerName != undefined) {
        this.subtitle =
          "Before you contact this provider, speak with a certified financial coach to make sure debt settlement is the best solution for you";
        this.form.preferredProvider = this.providerName;
      }

      axios.get(`https://api.ipify.org?format=json`)
        .then(response => {
          this.form.anonymousIP = response.data.ip;
        })
    },
    methods: {
      onSubmit(evt) {
        evt.preventDefault()

        if (this.$refs['leadForm'].checkValidity() !== false) {
          this.isLoading = true

          if (this.page == 0) {
            setTimeout(() => {
              this.isLoading = false
              this.page = 1
              this.title = "WE CAN HELP!"
              this.subtitle =
                "<span>Debt relief options are available!</span> Speak to a certified financial coach – this is a free service made possible by our partners.  "
              this.loadingMessage = "Transferring your data..."
            }, 2000);
          } else if (this.page == 1) {
              // eslint-disable-next-line
              this.marketoForm.addHiddenFields(this.form);
              this.marketoForm.submit();
          }
        }
      }
    }
  }
</script>

<style lang="scss">
  form {

    label,
    legend {
      font-weight: 500 !important;
      margin-bottom: 0.3rem;
      text-align: left;
    }

    .form-control,
    .custom-select {
      border: 2px solid #CED4DA;
      border-radius: 3px;
    }
  }

  .get-started-widget {

    .logo {
      width: 70%;
      max-width: 175px;
    }

    .gs-form {
      box-shadow: 0 4px 8px 0 rgba(90, 90, 90, 0.4);
      background-color: #FFFFFF;
      max-width: 410px;
    }

    .gs-tag {
      width: 90%;
      max-width: 375px;

      @media only screen and (min-width: 992px) {
        margin-left: -50px;
      }
    }


    h1 {
      font-size: 1.75rem;

      span {
        color: var(--primary);
      }

      @media only screen and (min-width: 992px) {
        font-size: 2rem;
      }
    }

    .subtitle {
      font-weight: 500;
      font-size: 0.95rem;

      span {
        color: var(--primary);
        font-weight: 600;
      }
    }

    .custom-checkbox {
      text-align: left;

      label {
        font-weight: 400 !important;
      }
    }
  }
</style>
