<template>
  <div class="review">
    <star-rating :increment="0.5" :star-size="16" :read-only="true" :show-rating="false" :rating="review.rating"></star-rating>
    <p>{{ review.description }}</p>
    <img v-bind:src="review.imgURL">
    <p class="from ml-2">{{ review.from }}</p>
  </div>
</template>

<script>
import StarRating from 'vue-star-rating'

export default {
  components: {
    StarRating
  },
  props: {
    review: {
      rating: Number,
      description: String,
      imgURL: String,
      from: String
    }
  }
}
</script>

<style lang="scss" scoped>
  .review {
    border-left: 4px solid var(--primary);
    padding: 10px 15px;
    text-align: left;
  }

  img {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    display: inline-block;
  }

  .from {
    display: inline-block;
    font-size: 0.75rem;
    font-weight: 500;
    color: #9D9D9D;
  }
</style>
