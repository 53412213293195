import Vue from 'vue'
import App from './App.vue'
import router from './router'
import BootstrapVue from 'bootstrap-vue'
import './custom.scss'
import VueMask from 'v-mask'
var VueScrollTo = require('vue-scrollto');
import VueGtag from "vue-gtag";
import VueGtm from 'vue-gtm';

Vue.use(VueScrollTo, {
  offset: -150,
})
Vue.use(VueMask);
Vue.use(BootstrapVue);

Vue.use(VueGtag, {
  config: { id: "UA-4278442-30" }
}, router);

Vue.use(VueGtm, {
  id: ['GTM-5X4QB8Q', 'GTM-5X4QB8Q'],
  vueRouter: router
});



Vue.config.productionTip = false


//Using this to forward UTM parameters
//TODO: Abstract
function hasQueryParams(route) {
  return !!Object.keys(route.query).length
}

router.beforeEach((to, from, next) => {
   if(!hasQueryParams(to) && hasQueryParams(from)){
    next({name: to.name, query: from.query});
  } else {
    next()
  }
})


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

